/// <reference path="../groupthink-js.d.ts" />

import React, { useCallback } from 'react';
import { fetcher, apiRequest } from '../lib';
import useSWR, { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';

export const useMeeting = (
  agendaId?: string,
  id?: string,
  options?: {
    useRealtimeResource?: (eventName, channel, mutate, url) => void;
  }
) => {
  const url = agendaId && id ? `/v1/agendas/${agendaId}/meetings/${id}` : null;
  const { useRealtimeResource } = options || {};
  const {
    data: meeting,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'meeting.show'>>(url, fetcher, {
    keepPreviousData: true,
  });

  useRealtimeResource?.(
    '.MeetingUpdated',
    id ? `App.Models.Agenda.Meeting.${id}` : false,
    mutate,
    url
  );

  const sendRecap = <RouteName = 'meeting.sendRecap'>(
    email: string, // email to send recap to
    { setErrors, setIsUpdating, onSuccess }: Groupthink.UpdateOperationOptions<RouteName> = {}
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/meetings/${id}/send_recap`, mutate, 'POST', {
      setErrors,
      setLoading: setIsUpdating,
      payload: { email },
      onSuccess,
    });

  return {
    meeting: meeting?.data,
    isLoading,
    isError: error,
    mutate,
    sendRecap,
  };
};

export const useTranscription = (
  agendaId?: string,
  id?: string,
  options?: {
    refreshInterval?: number;
  }
) => {
  const { refreshInterval = 0 } = options || {};

  const { onErrorRetry } = useSWRConfig();
  const {
    data: transcription,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'meeting.transcription'>>(
    () =>
      Boolean(agendaId) && Boolean(id)
        ? `/v1/agendas/${agendaId}/meetings/${id}/transcription`
        : false,
    fetcher,
    {
      refreshInterval,
      keepPreviousData: true,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Use the default backoff algorithm otherwise.
        // @ts-ignore
        onErrorRetry(error, key, config, revalidate, { retryCount });
      },
    }
  );

  return {
    transcription: transcription?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useTranscriptionNotes = (
  agendaId?: string,
  id?: string,
  agenda_item_id?: string,
  options?: {
    refreshInterval?: number;
  }
) => {
  const { refreshInterval = 0 } = options || {};

  const {
    data: transcription_notes,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'meeting.transcriptionNotes'>>(
    () =>
      Boolean(agendaId) && Boolean(id) && Boolean(agenda_item_id)
        ? `/v1/agendas/${agendaId}/meetings/${id}/transcription_notes/${agenda_item_id}`
        : Boolean(agendaId) && Boolean(id)
          ? `/v1/agendas/${agendaId}/meetings/${id}/transcription_notes`
          : false,
    fetcher,
    { refreshInterval, keepPreviousData: true }
  );

  return {
    // @ts-ignore
    transcription_notes: transcription_notes?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useMeetings = (agendaId?: string) => {
  const getKey = (pageIndex, previousPageData) => {
    if (!agendaId) {
      return null;
    }

    // reached the end
    if (previousPageData && !previousPageData.data) return null;

    // first page, we don't have `previousPageData`
    if (pageIndex === 0) return `/v1/agendas/${agendaId}/meetings`;

    return `/v1/agendas/${agendaId}/meetings?cursor=${previousPageData.meta.next_cursor}`;
  };

  const { data, error, size, setSize, isLoading, mutate } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData),
    fetcher,
    {
      keepPreviousData: true,
      revalidateFirstPage: false,
    }
  );

  const hasMorePages = data?.[data?.length - 1]?.meta?.next_cursor !== null;

  const meetings = React.useMemo(() => {
    if (!data || !Array.isArray(data)) return [];

    return data.reduce((allMeetings, page, index) => {
      if (!page) {
        console.warn(`Page ${index} is undefined`);
        return allMeetings;
      }

      if (Array.isArray(page)) {
        return [...allMeetings, ...page];
      }

      if (typeof page === 'object' && Array.isArray(page.data)) {
        return [...allMeetings, ...page.data];
      }

      console.warn(`Unexpected page structure at index ${index}:`, page);
      return allMeetings;
    }, []);
  }, [data]);

  const active_meeting = meetings?.find((m) => !m.ended_at && m.started_at);

  const createMeeting = ({ setErrors, setIsCreating, onSuccess, payload }) =>
    apiRequest<'meeting.store'>(`/v1/agendas/${agendaId}/meetings`, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateMeeting = useCallback(
    <RouteName = 'meeting.update'>(
      meetingId: string,
      {
        setErrors,
        setIsUpdating,
        onSuccess,
        payload,
      }: Groupthink.UpdateOperationOptions<RouteName> = {}
    ) =>
      apiRequest<RouteName>(`/v1/agendas/${agendaId}/meetings/${meetingId}`, mutate, 'PUT', {
        setErrors,
        setLoading: setIsUpdating,
        payload,
        onSuccess,
      }),
    [agendaId, mutate]
  );

  const deleteMeeting = async <RouteName = 'meeting.destroy'>(
    meetingId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName> = {}
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/meetings/${meetingId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  const endMeeting = useCallback(
    (options?: { meetingId?: string } & Groupthink.UpdateOperationOptions<'meeting.update'>) => {
      const meetingId = options?.meetingId ?? active_meeting?.id;
      if (meetingId) {
        // @ts-ignore
        return updateMeeting(meetingId, { payload: { end: true }, ...options });
      }

      return;
    },
    [active_meeting, updateMeeting]
  );

  return {
    meetings,
    active_meeting,
    isLoading,
    hasMorePages,
    size,
    setSize,
    isError: error,
    mutate,
    createMeeting,
    updateMeeting,
    deleteMeeting,
    endMeeting,
  };
};

// the URL the user should be at during this active meeting
// or the summary URL if the meeting is over
export const selectMeetingUrl = (meeting) => {
  if (!meeting) return null;

  if (meeting.started_at && !meeting.ended_at && meeting.conferencing?.driver == 'groupthink') {
    return `/agendas/${meeting.agenda_id}`;
  }

  return `/agendas/${meeting.agenda_id}/meetings/${meeting.id}`;
};
